import React, { useEffect, useState } from "react";
import {
  Bg,
  BoxContainer,
  Underline,
} from "../../Components/Styles/PageStyles";
import Images from "../../Images";
import Navbar from "../../Components/Navbar";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BedIcon from "../../Components/AddTenants/BedIcon";
import TenantForm from "../../Components/AddTenants/TenantForm";
import RoomDetailForm from "../../Components/AddTenants/RoomDetailForm";
import Loading from "../../Components/Loading";

const AddTenant = () => {
  const { roomId, propId, clientId } = useParams();
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.UserReducer.user);
  const [selectedBed, setSelectedBed] = useState(undefined);
  const [tenantData, setTenantData] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [selectedBedRoomData, setSelectedBedRoomData] = useState(undefined);
  const [selectedBedData, setSelectedBedData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedBed) {
      vacantBedData.data.data.beds.map((i) => {
        if (i.id == selectedBed) {
          setSelectedBedRoomData(i);
          if (selectedBed < 0)
            toast.success("All Rooms are selected", { hideProgressBar: false });
        }
      });
    }
  }, [selectedBed]);
  const fetchVacantBeds = async () => {
    if (roomId) {
      const body = new FormData();
      body.append("roomId", roomId);
      let response = await axios.post(`${Config.apiUrl}/getvacantbed`, body, {
        headers: {
          authorization: "Bearer " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
      return response;
    }
  };

  const vacantBedData = useQuery({
    queryKey: ["vacantBed"],
    queryFn: fetchVacantBeds,
    onSettled: (data) => {
      if (data.status === 200) {
        if (location.state.occupied === 0)
          data?.data?.beds?.push({ id: -1, content: "Select All Beds" });
      }
    },
  });

  const postTenantData = async (values) => {
    setLoading(true);
    if (selectedBed) {
      const API_END_POINT = selectedBed >= 0 ? "addtenant" : "tenantinallrooms";
      const body = new FormData();

      const rentalCycle = values.rentalCycle.slice(5);

      body.append("clientId", clientId);
      body.append("roomId", roomId);
      body.append("propId", propId);
      body.append("bedId", selectedBed);
      body.append("agreementStartDate", values.moveInDate);
      const keys = Object.keys(values);
      keys.map((i) => {
        if (i === "rentalCycle") {
          body.append("rentalCycle", rentalCycle);
          return;
        }
        body.append(i, values[i]);
      });

      const tenantKeys = Object.keys(tenantData);
      tenantKeys.map((i) => {
        body.append(i, tenantData[i]);
      });

      let response = await axios
        .post(`${Config.apiUrl}/${API_END_POINT}`, body, {
          headers: {
            authorization: "Bearer " + user.token,
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        })
        .then((res) => {
          toastDataSuccess();
          setLoading(false);
          navigate("/beds/" + roomId);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            toastDataAlreadyExist(err.response.data.msg);
            setLoading(false);
          }
        });

      return response;
    } else {
      toastSelectBed();
    }
  };

  const toastDataSuccess = () =>
    toast.success("Tenant Added Successfully ", {
      hideProgressBar: false,
    });

  const toastDataAlreadyExist = (data) =>
    toast.warn(data, {
      hideProgressBar: false,
    });

  const toastSelectBed = () =>
    toast.warn("Please Select Bed Id", {
      hideProgressBar: false,
    });

  const steps = ["Tenant Form", "Room Form"];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        {vacantBedData?.data?.data === undefined ||
        vacantBedData?.isLoading ||
        vacantBedData?.isRefetching ||
        loading ? (
          <Box sx={{ width: "100%" }}>
            <Loading className="w-full h-full justify-center items-center" />
          </Box>
        ) : (
          <BoxContainer>
            <div className="flex w-full justify-between items-center">
              <div>
                <Title>
                  {t("Select Bed ")}
                  <span className="text-primary-color">
                    ( {location?.state?.roomNum} )
                  </span>
                </Title>
                <Underline />
              </div>
            </div>
            {vacantBedData?.data?.data?.msg === "No data found" ? (
              <h1 className="mt-8 text-4xl ">
                {vacantBedData?.data?.data?.msg}
              </h1>
            ) : (
              <div>
                <div className="flex items-end flex-wrap gap-5 mt-8">
                  {vacantBedData?.data?.data?.msg !== "No data found" &&
                    (vacantBedData?.data?.data?.beds).map((i) => (
                      <BedIcon
                        id={i.id}
                        setSelectedBed={setSelectedBed}
                        selectedBed={selectedBed}
                        occupied={location.state.occupied}
                        data={i}
                        setSelectedBedData={setSelectedBedData}
                        selectedBedData={selectedBedData}
                      />
                    ))}
                </div>

                <Box
                  sx={{ width: "100%" }}
                  className="mt-8 border rounded p-5 shadow-lg"
                >
                  <div className="flex w-full justify-between items-center mb-8">
                    <div>
                      <Title className="text-primary-color">
                        {activeStep === 0
                          ? t("Tenant Details")
                          : t("Room Details")}
                      </Title>
                      <Underline className="bg-primary-color" />
                    </div>
                  </div>
                  <Box sx={{ width: { md: "50%", sm: "100%" } }}>
                    <Stepper nonLinear activeStep={activeStep}>
                      {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                          <StepButton
                            color="inherit"
                            sx={{ cursor: "default" }}
                          >
                            {label}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <div>
                    {allStepsCompleted() ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography sx={{ mb: 1 }}>
                          {activeStep === 0 ? (
                            <TenantForm
                              setTenantData={setTenantData}
                              setActiveStep={setActiveStep}
                              selectedBed={selectedBed}
                              tenantData={tenantData}
                            />
                          ) : (
                            <RoomDetailForm
                              selectedBed={selectedBed}
                              postTenantData={postTenantData}
                              selectedBedRoomData={selectedBedRoomData}
                              selecteBedData={selectedBedData}
                            />
                          )}
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                          {activeStep === 0 ? (
                            <Button
                              sx={{
                                mr: 1,
                                background: "#1976d3",
                                color: "white",
                                padding: "10px",
                                ":hover": {
                                  background: "white",
                                  color: "#1976d3",
                                },
                              }}
                              form="tenant-form"
                              type="submit"
                            >
                              Next
                            </Button>
                          ) : (
                            <div className="gap-5 flex">
                              <Button
                                onClick={() => {
                                  setActiveStep(0);
                                }}
                              >
                                Back
                              </Button>
                              <Button
                                sx={{
                                  mr: 1,
                                  background: "#1976d3",
                                  color: "white",
                                  padding: "10px",
                                  ":hover": {
                                    background: "white",
                                    color: "#1976d3",
                                  },
                                }}
                                form="room-detail-form"
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          )}
                        </Box>
                      </React.Fragment>
                    )}
                  </div>
                </Box>
                <br />
              </div>
            )}
          </BoxContainer>
        )}
      </Bg>
    </>
  );
};

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

export default AddTenant;
