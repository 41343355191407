import React, { useState } from "react";

const Cards = ({ data, mutateData }) => {
  const [value, setValue] = useState(
    data.services.filter((i) => {
      if (i.status === 2) return i.name;
    })[0]?.name // Add optional chaining in case the filter result is empty
  );
  
  const TYPE = ["", "Aadhaar Upload", "Aadhaar Number", "Pan Card"];

  return (
    <div className="border rounded-lg shadow-md p-6 bg-white">
      <div className="grid grid-cols-3 gap-4">
        {/* Type Section */}
        <div className="">
          <h2 className="text-2xl font-semibold text-blue-600">{TYPE[data.Type]}</h2>
        </div>

        {/* Select Dropdown */}
        <div className="">
          <select
            onChange={(e) => {
              setValue(e.target.value);
              let type = data.Type;
              mutateData({ value: e.target.value, type });
            }}
            value={value}
            className="border border-gray-300 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
          >
            {data.services.map((item, key) => (
              <option key={key} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>

        {/* Date Updated */}
        <div className=" flex items-center justify-center">
          <div className="text-sm text-gray-500">
            <span className="font-medium text-gray-800">Last Updated:</span>{" "}
            {new Date(data.updatedAt).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
