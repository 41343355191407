import React, { useEffect, useState } from "react";
import Model from "../Model";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { QueryClient } from "react-query";
import Loading from "../Loading";
import { SubmitBtn } from "../Styles/InputStyles";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";


const CheckBox = ({ label, checked, onChange, value,isDisabled }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      className={`relative flex items-center gap-3 border rounded-full px-4 py-2  transition-all
      ${isChecked && !isDisabled ? 'bg-blue-100 border-blue-500' : 'bg-white'} ${isDisabled? "cursor-not-allowed  border-gray-300 bg-opacity-50 bg-gray-100 " : "cursor-pointer  border-blue-300"}`}
    >
       <input
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(value)}
        disabled={isDisabled}
        className={`appearance-none w-5 h-5 rounded-full border  checked:border-transparent relative ${isDisabled?"border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300":"border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"}`}
      />
      {isChecked && (
        <span className={`absolute w-5 h-5  rounded-full flex justify-center items-center ${isDisabled?"bg-gray-500 text-white":"bg-blue-500 text-white"}`}>
          &#10004; {/* Checkmark */}
        </span>
      )}
      <p className="text-gray-500">{label}</p>
    </label>
  );
};

const LinkStaff = ({
  setLinkCharges,
  selectedData,
  propertyList,
  LinkStaffMutate,
  LinkStaffLoading,
}) => {
  const [checkData, setCheckData] = useState(new Map());
  const { clientId } = useParams();

  // Handle checkbox state change
  const handleCheckboxChange = (propId) => {
    setCheckData((prev) => new Map(prev).set(propId, !prev.get(propId)));
  };

  const postData = async () => {
    LinkStaffMutate(checkData)  
  };

  // Initialize checkbox states based on selected data
  useEffect(() => {
    const initialCheckData = new Map();
    propertyList.forEach((item) => {
      const isLinked = selectedData.staffs?.some(
        (staff) => staff.propId === item.propId
      );
      initialCheckData.set(item.propId, isLinked);
    });
    setCheckData(initialCheckData);
  }, [selectedData, propertyList]);

  console.log(selectedData)
  console.log(checkData)

  return (
    <Model setOpenModel={setLinkCharges} width="w-11/12 max-w-lg">
      {LinkStaffLoading ? (
        <Loading />
      ) : (
        <StyledWrapper>
          <StyledTitle>Link Staffs</StyledTitle>
          {selectedData.status==2 && <p className="text-sm text-gray-400 mb-3">Please change the status from inactive to active to procceed further.</p>}
          <div className={`grid grid-cols-2 gap-2 gap-y-4 ${selectedData.status==2?"cursor-not-allowed":""}`}>
          {propertyList.map((item) => (
              <CheckBox
                key={item.propId}
                label={item.propName}
                checked={checkData.get(item.propId)}
                onChange={handleCheckboxChange}
                value={item.propId}
                isDisabled={selectedData.status==2?true:false}
              />
            ))}
          </div>
          <BtnWrapper>
            <SubmitBtn type="submit" onClick={postData}>
              Submit
            </SubmitBtn>
          </BtnWrapper>
        </StyledWrapper>
      )}
    </Model>
  );
};

export default LinkStaff;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;

const StyledWrapper = tw.div`px-8 py-6 bg-white `;
const StyledTitle = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const StyledSubmitBtn = tw(
  SubmitBtn
)`w-full py-3 rounded-md bg-blue-500 text-white hover:bg-blue-600 shadow hover:shadow-lg transition-shadow duration-200 ease-in-out`;
