import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import Model from "../Model";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SubmitBtn } from "../Styles/InputStyles";
import { useSelector } from "react-redux";
import Loading from "../Loading";

export const AddExtraCharges = ({
  setAddCharges,
  data,
  AddChargesLoading,
  AddChargesMutate,
}) => {

  const [isDisabled,setIsDisabled]=useState()
  const ExtraChargesSchema = Yup.object().shape({
    chargesType: Yup.number()
      .min(1)
      .max(5)
      .required("Please select charges types"),
    amount: Yup.string()
      .required("Please specify the amount")
      .matches("^[0-9]*$", "Only Numbers are allowed")
      .test(
        "is-greater-than-zero",
        "Amount must be greater than 0",
        (value) => Number(value) > 0
      ),
    repetation: Yup.number()
      .min(1)
      .max(2)
      .required("Please specify the amount to be one time or monthly")
      .when("chargesType", (chargesType, schema) => {
        if (chargesType[0] === 1 || chargesType[0] === 3) {
          return schema.oneOf(
            [2],
            "Only Monthly Charges are applicable on " +
              EXTRA_CHARGES_TYPE[chargesType[0]]
          );
        } else if (chargesType[0] === 2) {
          return schema.oneOf(
            [1],
            "Only One time Charges are applicable on Registration"
          );
        }

        return schema;
      }),
    linkedProp: Yup.array()
      .min(1)
      .of(Yup.string().required())
      .required("Please select the linked property"),
  });

  const InitialValues = {
    chargesType: "",
    amount: "",
    repetation: "",
    linkedProp: "",
  };

  const postAddExtraCharges = async (values) => {
    AddChargesMutate(values)
  };

  const extraChargesFormik = useFormik({
    initialValues: InitialValues,
    validationSchema: ExtraChargesSchema,
    onSubmit: (values) => {
      postAddExtraCharges(values);
    },
  });

  const EXTRA_CHARGES_TYPE = [
    "Please Select Charges Type",
    "Electricity",
    "Registration",
    "Tech Charges",
    "Maintenance",
    "Others",
  ];

  const REPETATION = ["Please Select repetation amount", "Once", "Monthly"];

  useEffect(()=>{
    if(extraChargesFormik.values.chargesType==2){
      extraChargesFormik.setFieldValue("repetation","1")
      setIsDisabled(true)
    }
    else if(extraChargesFormik.values.chargesType==1 || extraChargesFormik.values.chargesType==3){
      extraChargesFormik.setFieldValue("repetation","2")
      setIsDisabled(true)
    }
    else
    {
      setIsDisabled(false)
    }

  },[extraChargesFormik.values.chargesType])

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setAddCharges}>
      {AddChargesLoading ? (
        <Loading />
      ) : (
        <Wrapper>
          <Title>Add Charges</Title>
          <form onSubmit={extraChargesFormik.handleSubmit} className="my-2">
            <p className="text-xs md:text-sm lg:text-base text-gray-600 my-1">
              Charge Type
            </p>
            <select
              onChange={extraChargesFormik.handleChange}
              onBlur={extraChargesFormik.handleBlur}
              value={extraChargesFormik.values.chargesType}
              name="chargesType"
              className="border rounded"
            >
              {EXTRA_CHARGES_TYPE.map((item, key) => (
                <option key={key} value={key}>
                  {item}
                </option>
              ))}
            </select>
            <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
              {extraChargesFormik.touched.chargesType &&
                extraChargesFormik.errors.chargesType}
            </div>
            <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
              Amount
            </p>
            <input
              type="text"
              value={extraChargesFormik.values.amount}
              name="amount"
              onChange={extraChargesFormik.handleChange}
              onBlur={extraChargesFormik.handleBlur}
              className="border rounded"
              placeholder={
                extraChargesFormik.values.chargesType === "1"
                  ? "Add electricity Unit "
                  : "₹ Add Amount"
              }
            />
            <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
              {extraChargesFormik.touched.amount &&
                extraChargesFormik.errors.amount}
            </div>

            <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
              Repetation
            </p>
            <select
              onChange={extraChargesFormik.handleChange}
              onBlur={extraChargesFormik.handleBlur}
              value={extraChargesFormik.values.repetation}
              name="repetation"
              className="border rounded"
              disabled={isDisabled}
            >
              {REPETATION.map((item, key) => (
                <option key={key} value={key}>
                  {item}
                </option>
              ))}
            </select>
            <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base">
              {extraChargesFormik.touched.repetation &&
                extraChargesFormik.errors.repetation}
            </div>
            <p className="text-xs md:text-sm lg:text-base text-gray-600 mb-1">
              Property name
            </p>
            <div className="flex flex-col text-sm justify-start items-start max-w-fit space-y-2 pb-2">
              {data.map((item, key) => (
                <div className="flex justify-start items-center space-x-1" key={key}>
                  <input
                    key={key}
                    type="checkbox"
                    name="linkedProp"
                    value={item.id}
                    onChange={extraChargesFormik.handleChange}
                    onBlur={extraChargesFormik.handleBlur}
                  />{" "}
                  <p className="text-cs md:text-sm lg:text-base text-gray-600 min-w-fit">
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
            <div className="text-rose-400 mb-1 text-xs md:text-sm lg:text-base pb-2">
              {extraChargesFormik.touched.linkedProp &&
                extraChargesFormik.errors.linkedProp}
            </div>
            <BtnWrapper>
              <SubmitBtn type="submit">Submit</SubmitBtn>
            </BtnWrapper>
          </form>
        </Wrapper>
      )}
    </Model>
  );
};
const Wrapper = tw.div`px-4`;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6`;
const BtnWrapper = tw.div`w-full flex items-center justify-center space-x-10 mt-8`;
