import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config, { PaginationLimit } from "../../Config";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { IoInformationCircleOutline } from "react-icons/io5";

import { Tooltip } from "react-tooltip";

const Table = ({
  ApiData,
  setSelectedData,
  setEditUser,
  setRoomUser,
  setViewDetail,
  userType,
  roomDetailOwnerMutate,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const { roomStatus } = Config;

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };

  const room = (room) => {
    setRoomUser(true);
  };

  const viewDetail = (room) => {
    setViewDetail(true);
    setSelectedData(room);
  };

  const goToBeds = (roomId) => {
    navigate(`/beds/${roomId}`);
  };

  const handleRoomClick = (roomItem, floorNumber) => {
    // const payload = {
    //   floor: roomItem.floor,
    //   room: roomItem.roomNum,
    // };

    // roomDetailOwnerMutate(payload, {
    //   onSuccess: (response) => {     
    //     // console.log("API Response:", response);
    //   },
    //   onError: (error) => {
    //     // console.error("Error :", error);
    //   },
    // });
    roomItem.floorNumber=floorNumber;
    setSelectedData(roomItem);
    setRoomUser(true);
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.flatMap((item, index) => {
        const [floorNumber, rooms] = Object.entries(item)[0];
        return {
          key: `${floorNumber}-${index}`,
          floor: getFloor(floorNumber),
          room: getRoom(rooms, floorNumber),
          action: ActionBtns(floorNumber),
        };
      });
      setData(tempData);
    }
  }, [ApiData]);
  const getFloor = (floorNumber) => {
    return (
      <div className="flex flex-col gap-[2px] w-[70px]">
        <p className="text-zinc-900 font-semibold">Floor {floorNumber}</p>
      </div>
    );
  };

  const getRoom = (rooms, floorNumber) => {
    const roomList = Array.isArray(rooms) ? rooms : [];

    return (
      <div className="flex flex-wrap gap-4">
        {roomList.map((roomItem, index) => (
          <span
            data-tooltip-id={`tooltip-${roomItem.roomId}`}
            data-tooltip-content={roomStatus[roomItem.status]}
            data-tooltip-delay-show={1000}
            data-tooltip-place="top"
            data-tooltip-variant="info"
            key={index}
            onClick={
              roomItem.status === 1 ||
              roomItem.status === 2 ||
              roomItem.status === 3
                ? () => {
                    handleRoomClick(roomItem, floorNumber);
                    // room(roomItem);
                  }
                : undefined
            }
            className={`flex justify-center items-center h-14 w-14 text-md rounded font-semibold cursor-pointer 
              ${
                roomItem.status === 1
                  ? roomItem.roomOptionId == null
                    ? "bg-gray-300"
                    : "bg-gray-500 text-gray-200"
                  : roomItem.status === 2
                  ? roomItem.roomOptionId == null
                    ? "bg-green-300"
                    : "bg-green-500 text-gray-200"
                  : roomItem.status === 3
                  ? roomItem.roomOptionId == null
                    ? "bg-purple-300"
                    : "bg-purple-500 text-gray-200"
                  : roomItem.status === 4
                  ? roomItem.roomOptionId == null
                    ? "bg-red-300"
                    : "bg-red-500 text-gray-200"
                  : "bg-yellow-100"
              }
            `}
          >
            <span>{roomItem.roomNum}</span>
            <Tooltip id={`tooltip-${roomItem.roomId}`} />
          </span>
        ))}
      </div>
    );
  };

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        {userType != Config.userType.MARKETING && (
          <Btn title="Edit Tenants" onClick={() => edit(item)}>
            <MdModeEdit className="text-gray-700" />
          </Btn>
        )}
      </ActionBtnWrapper>
    );
  };

  const InformationCircle = () => {
    return (
      <>
        <IoInformationCircleOutline
          className="text-lg"
          data-tooltip-id="info-circle"
        />
        <Tooltip
          id="info-circle"
          style={{
            backgroundColor: "#f1f4f9",
            color: "#222",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
          render={({ content }) => (
            <span>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-gray-500"></div>
                <div>Vacant</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-green-500"></div>
                <div>Semi Occupied</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-purple-500"></div>
                <div>Occupied</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-purple-300"></div>
                <div>Occupied with No Options</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-green-300"></div>
                <div>Semi Occupied with No Options</div>
              </div>
              <div className="flex gap-3">
                <div className="w-[15px] h-[15px] bg-gray-300"></div>
                <div>Vacant with No Options</div>
              </div>
            </span>
          )}
        />
      </>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      return [
        {
          Header: "Floor",
          accessor: "floor",
        },
      ];
    }

    // Columns for larger screens
    return [
      {
        Header: "Floor",
        accessor: "floor",
      },
      {
        Header: "Rooms",
        accessor: "room",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className="flex gap-5 justify-start items-center">
                    {column.render("Header")}{" "}
                    {column.render("Header") === "Rooms" ? (
                      <InformationCircle></InformationCircle>
                    ) : (
                      ""
                    )}
                  </div>
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li className="flex-row gap-2">
                          <strong className="my-1">Rooms :</strong>
                          <span clasName="mt-2">{data[rowIndex].room}</span>
                        </li>
                        <li className="flex justify-start items-center gap-2">
                          <strong>Action: </strong>
                          <span>{data[rowIndex].action}</span>
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75 "
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
export default Table;
