import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import  { PaginationLimit } from "../../Config";
import tw from "tailwind-styled-components";
import Images from "../../Images";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import { IoMdMore } from "react-icons/io";
import { useSelector } from "react-redux";
import { FaLink } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";

const Tables = ({
  ApiData,
  setSelectedData,
  setEditStaff,
  setLinkStaff,
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [currentPage, setCurrentPage] = useState(0);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const [previousId, setPreviousId] = useState(null);
  const user = useSelector((state) => state.UserReducer.user);

  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  const ActionBtns = (item) => {
    const openMenu = (id) => {
      console.log("Button clicked, ID:", id);
      setPreviousId(openActionMenu);
      setOpenActionMenu((prevId) => {
        console.log("Previous open menu ID:", prevId);
        return prevId === id ? null : id;
      });
    };



    return (
      <ActionBtnWrapper>
        {openActionMenu === item.id && (
          <MenuOptions id="menuOptions">
            <MenuItem
              title="editstaff"
              onClick={() => {
                setEditStaff(true);
                setSelectedData(item);
                setOpenActionMenu(null);
              }}
            >
              <MdModeEditOutline className="text-gray-700 " /> Edit Staff
            </MenuItem>
            <MenuItem
              onClick={() => {
                setLinkStaff(true);
                setSelectedData(item);
                setOpenActionMenu(null)
              }}
            >
              <FaLink className="text-gray-700 " /> Link - Unlink
            </MenuItem>
            
          </MenuOptions>
        )}
        <Btn title="Actions" type="button" onClick={() => openMenu(item.id)}>
          <IoMdMore className="text-gray-600" size={24} />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRole = (indx) => {
    switch (parseInt(indx)) {
      case 1:
        return (
          <p className="bg-teal-500 text-white text-xs py-1 max-w-fit px-4 flex items-center justify-center rounded-full">
          Admin
          </p>
        );
      case 2:
        return (
          <p className="bg-lime-500 text-white text-xs py-1 max-w-fit px-4 flex items-center justify-center rounded-full">
            Warden
          </p>
        );
      case 3:
        return (
          <p className="bg-amber-500 text-white text-xs py-1 max-w-fit px-4 flex items-center justify-center rounded-full">

            Electrician
            </p>
        );
      case 4:
        return (
          <p className="bg-indigo-500 text-white text-xs py-1 max-w-fit px-4 flex items-center justify-center rounded-full">
            Plumber
          </p>
        );
      default:
        return (
          <p className="bg-rose-600 text-white text-xs py-1 max-w-fit px-4 flex items-center justify-center rounded-full">
            Not assigned
          </p>
        );
    }
  };

  const getTenantGender = (gender) => {
    if (gender === 1) {
      return 'Male';
    } else {
      return 'Female';
    }
  };

  const getName = (item) => {
    return (
      <div className="flex gap-2 items-center">
        <img src="/images/avatar.png" alt="Client" className="w-10 h-10 rounded-full overflow-hidden" />
        <div className="flex flex-col gap-[2px]">
          <p className="text-zinc-900 font-semibold w-[80px]">{item.name}</p>
          <p className="text-blue-600">{getTenantGender(item.gender)}</p>
          <p className="text-yellow-500 text-xs">#{item.mobile}</p>
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: getName(item),
        Gender: item.gender == 1 ? "Male" : "Female",
        Role: getRole(item.role),
        Number: <div className="text-yellow-500">#{item.mobile}</div>,
        Salary:item.salary==0?<div>--/--</div>:<div> ₹  {item.salary}</div>,
        action: ActionBtns(item),
        status:
          item.status ==1 ? (
            <div className="bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded">
              Active
            </div>
          ) : (
            <div className="bg-gray-100 text-gray-700 text-xs border-gray-300 border w-24 h-7 flex items-center justify-center rounded">
              Inactive
            </div>
          ),
      }));
      setData(tempData);
      //   console.log(tempData)
    }
  }, [ApiData, openActionMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest("#menuOptions") &&
        !event.target.closest(".action-btn")
      ) {
        setOpenActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const columns = useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Charges For",
          accessor: "name",
        },
        {
          Header: "Amount",
          accessor: "charges",
        },
      ];
    }

    // Columns for larger screens
    return [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Salary",
        accessor: "Salary",
      },
      {
        Header: "Role",
        accessor: "Role",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
      initialState: { pageIndex: currentPage, pageSize: PaginationLimit },
    },
    useSortBy,
    usePagination
  );
  useEffect(() => {
    setCurrentPage(pageIndex); // Update current page on state change
  }, [pageIndex]);
  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                        } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} hasData={ApiData?.length > 0}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()} hasData={ApiData?.length > 0}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>City:</strong> {data[rowIndex].city}
                        </li>
                        <li>
                          <strong>Created At:</strong> {data[rowIndex].date}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>
          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75 "
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};
const MenuItem = tw.div`flex items-center gap-2 px-4 py-2 text-base font-medium cursor-pointer hover:bg-gray-100`;
const MenuOptions = tw.div`absolute bottom-10 right-0 py-4 w-max bg-white z-50 rounded-lg shadow-lg border border-gray-200 flex flex-col`;
const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;
const Btn = tw.button`action-btn grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ActionBtnWrapper = tw.div`flex items-center gap-3 relative`;
// const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`${(props) => (props.hasData ? "border-b" : "")}`;
const Tr = tw.tr`${(props) =>
  props.hasData
    ? "border-b"
    : ""} rounded-md overflow-hidden hover:bg-slate-50`;
export default Tables;
